/* main.css */
/* Colors */
:root {
  --primary-color: #ff914d; /* Logo background color */
  --secondary-color: #18222c; /* Dark blue-gray */
  --accent-color: #1ABC9C; /* Teal */
  --white-color: #fbfbfb; /* Off-white */
  --background-color: #f4f4f9; /* Light gray background */
  --text-color: #333333; /* Dark gray text */
  --link-color: #1ABC9C; /* Teal for links */
  --link-hover-color: #ff6b6b; /* Coral for link hover */
}

body {
  font-family: "Lato" , sans-serif;
  color: var(--secondary-color);
  font-weight: 300;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}


/* Base styles for the navbar */
.navbar {
  background-color: #2C3E50;
  padding: 1rem 2rem; /* Adjust as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Navbar items (links) */
.navbar a {
  color: #FFFFFF; /* White color for general navbar items */
  text-decoration: none; /* Remove underline */
  margin: 0 1rem; /* Spacing between items, adjust as needed */
  padding: 0.5rem 1rem; /* Padding for a larger clickable area and visual appeal */
  transition: color 0.3s ease; /* Smooth transition for hover effects */
}

/* Hover and active states */
.navbar a:hover,
.navbar a.active {
  color: #1ABC9C; /* Teal for active/hover state */
}

/* Optional: For secondary items or icons */
.navbar .secondary-item {
  color: #B0B0B0; /* Light gray for secondary items */
}

/* If you want to use Coral for some specific interaction or highlight */
.navbar a.highlight {
  color: #FF6B6B; /* Coral for specific highlights */
}

/* Links */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 700;
}
a:hover {
  color: var(--secondary-color);
}

/* Buttons */
.white {
  color: var(--white-color);
}
.display-4 {
  font-weight: 700;
}
.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
border: none;
padding: 10px 20px;
border-radius: 4px;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
cursor: pointer;
transition: background-color 0.3s;
}

.btn-primary:hover {
background-color: #5d8079; /* Slightly darker shade of the primary color */
}


.decision-tree {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.branch {
  flex: 1;
  padding: 20px;
  background-color: #FF5722;
  border: 2px solid #FF5722;
  border-radius: 10px;
  margin: 10px;
}

.option {
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  transition: background-color 0.3s, border-color 0.3s;
}

.option:hover {
  background-color: #FF4500;
  border-color: #FF4500;
}
/* Resume */
.resume {
  background-color: #fafafa;
  padding: 3em;
}
.card-header {
  background-color: var(--accent-color);

}
/* ABout Homepage */
.about {
  background-color: #fafafa;
  padding:3em;

}

.about-header{
 background-size: cover;
    background-position: center;
    height:30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    margin-bottom: 2em;
}
/* Create circle for headshot */
.headshot {
 width: 100%;
  height: auto;
  border-radius: 100%;
  margin-bottom: 2em;
  object-fit: cover;
  border: 3px solid var(--primary-color);
  
}
/* Resume */
.resume-title {
  color: var(--white-color);
  font-weight: 700;
  text-transform: uppercase;
}
.resume-role {
  font-weight: 700;
}

.contact {
  background-color: var(--neutral-color);
  padding: 3em;
}

.skill-icon {
  color: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}

.skill-icon:hover {
  transform: translateY(-5px);
  color: var(--secondary-color);
}

.skill-icon p {
  margin-top: 1em;
  text-align: center;
}

/* Footer */
.footer {
  background-color: #2c3E50;
  padding: 3em;
  color: #fbfbfb;
}
/* add an amazing animation to the social icons */
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons a {
  color: #fbfbfb;
  margin: 0 1em;
  transition: transform 0.3s;
}

.social-icons a:hover {
  transform: translateY(-5px);
}
/* Project */

.project img {
  display: flex;
  justify-content: center;
  align-items: center;
  width:200px;
  margin: 3em;
}

.image {
  position: relative;
}

.image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-header {
  height: auto;
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.img-fluid.rounded-circle {
  max-width: 250px;
  border-radius: 50%;
}

ul.list-unstyled {
  margin-top: 20px;
}

ul.list-unstyled a {
  margin-right: 10px;
}
.resume-title {
  background-color: var(--accent-color);
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
}

.resume-role {
  color: var(--secondary-color);
  font-weight: bold;
  margin-bottom: 10px;
}