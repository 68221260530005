/* Projects.css */

@media (max-width: 767px) {
    .project {
      margin-bottom: 20px;
    }
  
    .card {
      margin: 0 auto;
    }
  
    .card-img-top {
      width: 100%;
      height: auto;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .project {
      margin-bottom: 20px;
    }
  
    .card {
      margin: 0 auto;
    }
  
    .card-img-top {
      width: 100%;
      height: auto;
    }
  }
  
  @media (min-width: 992px) {
    .project {
      margin-bottom: 20px;
    }
  
    .card {
      margin: 0 auto;
    }
  
    .card-img-top {
      width: 100%;
      height: auto;
    }
  }