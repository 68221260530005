:root {
  --primary-color: #2563eb;
  --primary-dark: #1d4ed8;
  --secondary-color: #64748b;
  --accent-color: #3b82f6;
  --background-light: #f8fafc;
  --background-white: #ffffff;
  --text-dark: #1e293b;
  --text-light: #64748b;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 90px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Card Hover Effects */
.hover-card {
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.hover-card:hover {
  transform: translateY(-10px);
}

/* Featured Card */
.featured {
  border: 2px solid var(--primary-color);
  transform: scale(1.05);
}

.featured:hover {
  transform: scale(1.05) translateY(-10px);
}

/* Pricing Styles */
.card-price {
  font-size: 2.5rem;
  font-weight: bold;
  color:#64748b;
}

.period {
  font-size: 1rem;
  color: var(--secondary-color);
}

.fa-ul {
  list-style-type: none;
  padding-left: 1.5em;
  margin-bottom: 2rem;
}

.fa-li {
  color: var(--accent-color);
}

.hourly-rate {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--background-light);
  border-radius: 20px;
  font-size: 1.1rem;
  color: var(--secondary-color);
}

.hourly-rate .period {
  font-size: 0.9rem;
}

.price-wrapper {
  margin-bottom: 1.5rem;
}

/* Section Enhancements */
section {
  padding: 100px 0;
}

.about img {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.portfolio {
  background-color: var(--background-white);
}

.about {
  background-color: var(--background-light);
}

.pricing {
  background-color: var(--background-white);
}

/* Portfolio Section Styles */
.portfolio {
  background-color: #fff;
}

.portfolio-content {
  height: 100%;
}

.portfolio-image {
  height: 100%;
  display: flex;
  align-items: center;
}

.portfolio img {
  border-radius: 8px;
  transition: transform 0.3s ease;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

@media (max-width: 768px) {
  section {
    padding: 60px 0;
  }

  .portfolio-content {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .portfolio-content .btn {
    margin: 0 auto;
  }
  
  .portfolio .tech-stack {
    justify-content: center;
  }
}

.portfolio img {
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.portfolio img:hover {
  transform: scale(1.02);
}

/* Button Styles */
.btn-primary {
  padding: 12px 30px;
  border-radius: 25px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
}

/* Navbar Styles */
.navbar {
    padding: 1rem 0;
    transition: all 0.3s ease;
}

.navbar-brand img {
    transition: transform 0.3s ease;
}

.navbar-brand:hover img {
    transform: scale(1.05);
}

.nav-link {
    font-weight: 500;
    padding: 0.5rem 1rem !important;
    margin: 0 0.2rem;
    color: var(--text-dark) !important;
    transition: all 0.3s ease;
}

.nav-link:hover {
    color: var(--primary-color) !important;
    transform: translateY(-2px);
}

.contact-link {
    background: var(--primary-color);
    color: white !important;
    border-radius: 25px;
    padding: 0.5rem 1.5rem !important;
}

.contact-link:hover {
    background: var(--primary-dark);
    color: white !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Responsive Adjustments */
@media (max-width: 991.98px) {
    .navbar-collapse {
        background: white;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        margin-top: 1rem;
    }

    .nav-link {
        padding: 0.7rem 1rem !important;
    }

    .contact-link {
        display: inline-block;
        margin-top: 0.5rem;
    }
}

/* Contact Page Styles */
.consulting-hero {
  padding: 4rem 0;
}

.consulting-hero img {
  max-width: 800px;
  width: 100%;
}

.services-overview {
  background-color: var(--background-light);
  border: 1px solid rgba(0,0,0,0.05);
}

.contact-form .form-control {
  border: 1px solid rgba(0,0,0,0.1);
  padding: 0.8rem 1rem;
}

.contact-form .form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(37, 99, 235, 0.1);
}

.contact-form textarea.form-control {
  min-height: 150px;
}

@media (max-width: 768px) {
  .consulting-hero {
    padding: 2rem 0;
  }
  
  .services-overview .col-md-4:not(:last-child) {
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}

/* Certification Styles */
.certification-card {
  background: var(--background-light);
  border-radius: 12px;
  border: 1px solid rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.certification-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.certification-card .text-primary {
  color: var(--primary-color) !important;
}
